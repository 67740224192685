import React from 'react'
const client =[
    {title: 'How to Hire', link:'/'},
    {title: 'Talent Marketplace', link:'/'},
    {title: 'Project Catalog', link:'/'},
    {title: 'Hire an Agency', link:'/'},
    {title: 'Enterprise', link:'/'},
    {title: 'Any Hire', link:'/'},
    {title: 'Contract-to-Hire', link:'/'},
    {title: 'Direct Contracts', link:'/'},
]
const talent =[
    {title: 'How to Find Work', link:'/'},
    {title: 'Direct Contracts', link:'/'},
    {title: 'Find Freelance Jobs Worldwide', link:'/'},
    {title: 'Find Freelance Jobs in the USA', link:'/'},
    
]
const resources =[
    {title: 'Help & Support', link:'/'},
    {title: 'Success Stories', link:'/'},
    {title: 'Upwork Reviews', link:'/'},
    {title: 'Resources', link:'/'},
    {title: 'Blog', link:'/'},
    {title: 'Community', link:'/'},
    {title: 'Affiliate Program', link:'/'},
    {title: 'Free Business tools', link:'/'},
]
const company =[
    {title: 'About Us', link:'/'},
    {title: 'Leadership', link:'/'},
    {title: 'Investor Relations', link:'/'},
    {title: 'Careers', link:'/'},
    {title: 'Our Impact', link:'/'},
    {title: 'Press', link:'/'},
    {title: 'Contact Us', link:'/'},
    {title: 'Trust, Safety & Security', link:'/'},
]
const Footer = () => {
  return (
    <div className='bg-green-950 text-white p-14 mt-7'>
    <div className="flex flex-col md:flex-row md:justify-start">

        <div className="text-white md:mr-24">
            <p className='my-6'>For Clients</p>
            <div>
                {client.map((e,idx)=>(
            <p key={idx} className='my-3'>{e.title}</p>
                ))}
            </div> 
        </div>
        <div className="text-white md:mx-20">
            <p className='my-6'>For Talent </p>
            <div>
                {talent.map((e,idx)=>(
            <p key={idx} className='my-3'>{e.title}</p>
                ))}
            </div>        
        </div>
        <div className="text-white md:mx-20">
            <p className='my-6'>For Talent </p>
            <div>
                {resources.map((e,idx)=>(
            <p key={idx} className='my-3'>{e.title}</p>
                ))}
            </div>        
        </div>
        <div className="text-white md:mx-20">
            <p className='my-6'>For Talent </p>
            <div>
                {company.map((e,idx)=>(
            <p key={idx} className='my-3'>{e.title}</p>
                ))}
            </div>        
        </div>
        
    </div>
    </div>
  )
}

export default Footer