import React from 'react'
import {AiFillStar} from 'react-icons/ai'

const jobs=[
    {jobTitle:'Development & IT ', rating:4.5,skills:1200},
    {jobTitle:'AI Services  ', rating:4.5,skills:1200},
    {jobTitle:'Design & Creative ', rating:4.5,skills:1200},
    {jobTitle:'Sales & Marketing  ', rating:4.5,skills:1200},
    {jobTitle:'Writing & Translation  ', rating:4.5,skills:1200},
    {jobTitle:'Admin & Customer Support ', rating:4.5,skills:1200},
    {jobTitle:'Finance & Accounting ', rating:4.5,skills:1200},
    {jobTitle:'Engineering & Architecture', rating:4.5,skills:1200},
    {jobTitle:'Development & IT ', rating:4.5,skills:1200},
    {jobTitle:'Development & IT ', rating:4.5,skills:1200},
    {jobTitle:'Development & IT ', rating:4.5,skills:1200},
    {jobTitle:'Development & IT ', rating:4.5,skills:1200},
]
const Brows = () => {
  return (
    <div className='lg:ml-24 mx-5 w-[89%]'>
    <h1 className='text-3xl font-semibold'>Browse talent by category </h1>
    <h6>Looking for work?</h6>
    {/* <div className='col md:flex flex-wrap mt-4'> */}
    <div className='grid grid-row-1 md:grid-rows-3 lg:grid-rows-2 sm:grid-flow-col  mt-4'>
    {jobs.slice(0,8).map((e,idx)=>(

        <div className='bg-slate-100 hover:bg-slate-200 rounded-md w-full md:w-[17rem] p-5  my-3' key={idx}>
            <h3 className='text-2xl font-semibold'>{e.jobTitle}</h3>
            <div className="flex justify-between row my-4 text-gray-600">
                <p className='flex row items-center'><AiFillStar className='text-green-600 text-xl'/>{e.rating} </p>      
                <p>{e.skills} skills</p>
            </div>
        </div>
    ))}
    </div>
    </div>
  )
}

export default Brows