import React from 'react'
import banner from '../images/undraw_Working_late_re_0c3y.png'
const Banner = () => {
  return (
    <div className='col md:flex  md:justify-around items-center my-20 '>
    <div className='md:w-[43%] w-[90%] mx-5 md:mx-2'>
    <h1 className='text-7xl font-medium text-green-600'>
    How work <br/>
should work
    </h1>
    <h4 className='text-2xl font-medium text-gray-600 my-6'>
    Forget the old rules. You can have the best people.
Right now. Right here.
    </h4>
    <button className='bg-green-600 text-white rounded-3xl py-2 px-5 my-4'>Get started</button>
    </div>
    <div className='hidden md:block'>
    <img src={banner} alt='banner' className='w-[24rem] h-[24rem]'/>
    </div>
    </div>
  )
}

export default Banner