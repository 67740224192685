import './App.css';
import Navbar from './componants/Navbar';
import Banner from './componants/Banner';
import Learn from './componants/Learn';
import Brows from './componants/Brows';
import About from './componants/About';
import Footer from './componants/Footer';

function App() {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <Learn/>
      <Brows/>
      <About/>
      <Footer/>
    </div>
  );
}

export default App;
