import React from 'react'

import banner from '../images/pexels-canva-studio-3194521.jpg'
import{FiEdit}from 'react-icons/fi'
import{AiOutlinePushpin} from 'react-icons/ai'
import {RiShieldStarLine} from 'react-icons/ri'

const instraction=[
    {icon:<FiEdit className='mt-2 mr-3 text-xl'/>,title:"No cost to join",desc:"Register and browse professionals, explore projects, or even book a consultation."},
    {icon:<AiOutlinePushpin className='mt-2 mr-3 text-2xl'/>,title:"Post a job and hire top talent",desc:"Finding talent doesn’t have to be a chore. Post a job or we can search for you!"},
    {icon:<RiShieldStarLine className='mt-2 mr-3 text-2xl'/>,title:"Work with the best—without breaking the bank",desc:"Upwork makes it affordable to up your work and take advantage of low transaction rates."},
]
const Learn = () => {
  return (
    <div className='col md:flex  md:justify-center items-start my-20 '>
        <div>
        <img src={banner} alt='banner' className='w-[30rem] lg:h-[26rem] md:h-[30rem] '/>
        </div>
        <div className='md:w-[43%] w-[90%] mx-5 md:mx-11 '>
        <h1 className='text-4xl font-medium'>Up your work game, it’s easy</h1>
        <div className='mt-7'>
        {instraction.map((e,idx)=>(

        <div className=' inline-flex my-2' key={idx}>
        {e.icon}
            <div >
            
                <h4 className='text-xl font-semibold'>{e.title}</h4>
                <p>{e.desc}</p>
            </div>
        </div>
        ))}
           
           
        </div>
        <div className='flex'>
        <button className='bg-green-600 text-white rounded-3xl py-2 px-5 my-4 mr-4 font-semibold'>Sign up for free</button>
        <button className='border-green-600 border-solid border-2 text-green-600 rounded-3xl py-2 px-5 my-4 font-semibold'>Learn How to hire</button>
        </div>
        </div>
    </div>
  )
}

export default Learn