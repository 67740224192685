import React from "react";
import About1 from "../images/pexels-bich-tran-2481177.jpg";
import {LuPencil} from 'react-icons/lu'
import {GiHandBag} from 'react-icons/gi'
import {TfiHeadphoneAlt} from 'react-icons/tfi'
const data=[
    {icon:<LuPencil className="mr-3 text-emerald-300"/>, title:"Access expert talent to fill your skill gaps"},
    {icon:<GiHandBag className="mr-3 text-emerald-300"/>, title:"Control your workflow: hire, classify and pay your talent "},
    {icon:<TfiHeadphoneAlt className="mr-3 text-emerald-300"/>, title:"Partner with Upwork for end-to-end support "},
]
const About = () => {
  return (
    <div className="lg:mx-24 my-28 ">
      <div className="flex bg-[#13544e] flex-col-reverse md:flex-row md:justify-between justify-center rounded-lg md:max-h-[33rem]">
        <div className="p-7 md:w-[70%]">
          <h4 className="text-white text-xl">Enterprise Suite </h4>
          <h1 className="text-white text-5xl my-7">
            {" "}
            This is how <br/><p className="text-green-200">good companies <br/> find good company.</p>{" "}
          </h1>
          <p className="text-white font-normal text-lg">
            Access the top 1% of talent on Upwork, and a full suite of hybrid
            workforce management tools. This is how innovation works now.{" "}
          </p>
          <div className="mt-4">
          {data.map((e,idx)=>(

            <p key={idx} className="flex row items-center text-white font-normal text-lg my-2">
              {e.icon} {e.title} {" "}
            </p>
          ))}
          </div>
          <button className='bg-white text-emerald-700 rounded-3xl py-2 px-5 my-4 mr-4 font-semibold'>Sign up for free</button>

        </div>
        <div>
          <img
            src={About1}
            alt="about"
            className="w-full md:w-[30rem] h-80 md:h-full rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
